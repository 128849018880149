import {useQuery} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {AxiosErrorToast} from '../general/ReusableFunctions';
import {IService} from '../../dto/Service.dto';
import {ICompanySchool} from '../../dto/CompanySchool.dto';
import {IHubspotContact} from '../../dto/Hubspot.dto';
import {IApiDataResponse, IApiSuccessMsgResponse} from '../../dto/API.dto';
import axiosInstance from '../../axiosConfig';
import {IOnboardCmySchools} from '../../store/Slices/OnboardCmySchoolsSlice';
import {IUserBlackListProp} from '../../dto/User.dto';

export interface ICompaniesPayload {
  company_name?: string | null;
  product: {id: number; primary: string; secondary: string; level: string};
  action_mode: string;
}

const getCompanyWithSchools = (company_id: number) => {
  return axiosInstance
    .get(`/v1/company/${company_id}/schools`)
    .then((response: AxiosResponse<IApiDataResponse<IOnboardCmySchools>>) => {
      return response.data?.data;
    })
    .catch((error: AxiosError) => {
      AxiosErrorToast(error, 'Error in Company and schools');
      throw error;
    });
};

export const useRqCompanyWithSchools = (
  company_id: number,
  enabled: boolean = true,
) => {
  const enableRender = company_id > 0 ? enabled : false;
  return useQuery({
    queryKey: ['api/v1/company', company_id, 'schools'],
    queryFn: () => getCompanyWithSchools(company_id),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enableRender,
  });
};

export interface ICompanyStorePayload {
  name: string;
  phone?: number;
  address?: string;
  address2?: string;
  state?: string;
  hubspot_id: number;
  discount: number;
  company_code: string;
  set_custom_name: boolean;
  branding_type: 1 | 2; //1-DistrictBranding , 2-SchoolBranding
  company_services: IService[];
  company_facilities: string[];
  company_schools: ICompanySchool[];
  contacts: IHubspotContact[];
  note: string;
  time_zone: string;
  level?: string;
  pr_email: string;
  zip: number;
  country?: string;
  city?: string;
}

export const storePartner = async (payload: ICompanyStorePayload) => {
  return axiosInstance
    .post(`/v1/company`, payload)
    .then((response: AxiosResponse<IApiSuccessMsgResponse>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
};

// Get company participation detials
type ICompanyParticipationResponse = {
  is_participation_enabled: boolean;
  redirect_to_review: boolean;
  participation_updated_at: string;
  participations_updated_by: IUserBlackListProp;
};
export const getCompanyParticipationApiPath = (companyId: number) =>
  `/v1/company/${companyId}/participation`;

const getCompanyParticipation = (companyId: number) => {
  return axiosInstance
    .get(getCompanyParticipationApiPath(companyId))
    .then(
      (
        response: AxiosResponse<
          IApiDataResponse<ICompanyParticipationResponse>
        >,
      ) => {
        return response.data.data;
      },
    );
};

export const useRqListCompanyParticipation = (
  companyId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getCompanyParticipationApiPath(companyId)],
    queryFn: () => getCompanyParticipation(companyId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};
