import {GridSortModel, GridSortDirection} from '@mui/x-data-grid';
import {AxiosError} from 'axios';
import {ITrackCategory} from './MediaTrack.dto';

export type MUICheckboxEvent = React.ChangeEvent<HTMLInputElement>;

export type IErrorResponse = {
  response?: IErrorData;
};

export type IConditionalOperators =
  | 'lte'
  | 'lt'
  | 'gt'
  | 'gte'
  | 'eq'
  | 'ne'
  | 'clip';

export type ILanguage = 1 | 2 | null; //1-Spanish, 2-Other language

export type IDataGridSearchSort = {
  search?: string;
  page: number;
  pageSize: number;
  field?: string;
  sort?: GridSortDirection;
  status?: string;
  playlistId?: number;
  category?: string;
};

export const defaultDataGridSearchSort = {
  page: 0,
  pageSize: 10,
  search: '',
  field: '',
  sort: null,
};

export type IDataGridProSearchSort = {
  search?: string;
  track_category?: ITrackCategory;
  page: number;
  pageSize: number;
  sort?: GridSortModel;
  genres?: number[];
  station_id?: number[];
};

export const defaultDataGridProSearchSort = {
  page: 0,
  pageSize: 10,
  search: '',
};
export type IErrorData = {
  data?: {
    message?: string;
  };
};

export type IApiState = {
  status: 'pending' | 'error' | 'success' | '';
  error: null | AxiosError;
  successMsg?: string;
};

export const defaultApiState: IApiState = {
  status: '',
  error: null,
  successMsg: '',
};

export type IUndefiningAll<T> = {[P in keyof T]?: T[P]};
