import {AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import {apiGet} from '../axiosConfig';
import {IApiDataResponse} from '../dto/API.dto';
import {IMusicGenre} from '../dto/MusicGenre.dto';
import {IUserBase} from '../dto/User.dto';
import {ISongRequestView} from '../react_query/song-requests/SongRequests';
import {ILanguage} from '../dto/Html.dto';
import {IProcessingCenter} from '../dto/IProcessingCenter.dto';

export interface IMusicReview {
  id: number;
  track_artist: string;
  track_name: string;
  company_name: string;
  requestable_type: string;
  status: string;
  created_at: string;
  reviews: IReviewer[];
  attributes: string;
  final_review_notes: string;
  music_review_status: IInternelStatus;
  music_review_ratings: INoPlayListRating[];
  review_files: IReviewFile[];
  bpm: number;
  year: number;
  review_status: string;
  language: ILanguage;
  internal_status: string;
  processing_center?: IProcessingCenter;
  is_locked?: boolean;
}

export interface IReviewFile {
  id: number;
  file_name: string;
  type: number;
  file_info: string;
  genres: number[];
  super_genres: number[];
}
export interface IReviewer {
  id: number;
  user: IUserBase;
  note: string;
  reviewer_status: number;
  created_at: string;
}

export type IListQueryParams = {
  search?: string;
  sort_field?: string;
  sort_order?: string;
  per_page?: number;
  page?: number;
};

export const apiGetMusicReview = (params: IListQueryParams) => {
  return apiGet(`/v1/music-review`, {params: params}).then(
    (response: AxiosResponse<IApiDataResponse<IMusicReview[]>>) =>
      response.data.data,
  );
};

export const apiGetMusicReviewByID = (id: number) => {
  return apiGet(`/v1/task/${id}/music-review-producer`).then(
    (response: AxiosResponse<IApiDataResponse<ISongRequestView>>) =>
      response.data.data,
  );
};

export interface INoPlayListRating {
  id: string;
  description: string;
  short_description: string;
}

const getNoPlayListRating = () => {
  return apiGet('/v1/no-play-list-rating').then(
    (response: AxiosResponse<IApiDataResponse<INoPlayListRating[]>>) => {
      return response.data.data;
    },
  );
};
export const useRqListNoPlayListRating = () => {
  return useQuery({
    queryKey: ['/v1/no-play-list-rating'],
    queryFn: () => getNoPlayListRating(),
    refetchOnWindowFocus: false,
    staleTime: 600000,
  });
};
const getInternelStatus = () => {
  return apiGet(`/v1/internal-status`).then(
    (response: AxiosResponse<IApiDataResponse<IInternelStatus[]>>) =>
      response.data.data,
  );
};
export const useRqListInternelStatus = () => {
  return useQuery({
    queryKey: [`/v1/internal-status`],
    queryFn: () => getInternelStatus(),
    refetchOnWindowFocus: false,
    staleTime: 600000,
  });
};

export interface IInternelStatus {
  id: number;
  name: string;
}

const getParentGenres = () => {
  return apiGet(`/v1/music-selection/parent-genre`).then(
    (response: AxiosResponse<IApiDataResponse<IMusicGenre[]>>) => {
      return response.data.data;
    },
  );
};

export const useRqParentGenres = () => {
  return useQuery({
    queryKey: ['api/v1/music-selection/parent-genre'],
    queryFn: () => getParentGenres(),
    refetchOnWindowFocus: false,
    staleTime: 600000,
  });
};

const getSubGenres = (parent_id: number) => {
  return apiGet(`/v1/music-selection/parent-genre/${parent_id}`).then(
    (response: AxiosResponse<IApiDataResponse<IMusicGenre[]>>) => {
      return response.data.data;
    },
  );
};

export const useRqSubGenres = (parent_id: number, enabled: boolean = true) => {
  return useQuery({
    queryKey: ['api/v1/music-selection/parent-genre/' + parent_id],
    queryFn: () => getSubGenres(parent_id),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};

const getMusicReviewTask = () => {
  return apiGet(`/v1/task/music-review-task`).then(
    (response: AxiosResponse<IApiDataResponse<IMusicReview[]>>) =>
      response.data.data,
  );
};
export const useRqListMusicReviewTask = () => {
  return useQuery({
    queryKey: [`/v1/task/music-review-task`],
    queryFn: () => getMusicReviewTask(),
    refetchOnWindowFocus: true,
    retry: false,
    enabled: true,
  });
};
