import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {useSelector} from 'react-redux';
import {InterpreterMode} from '@mui/icons-material';
import {useQueryClient} from '@tanstack/react-query';
import {useCallback, useEffect, useState} from 'react';
import {companyIdSelector} from '../../store/Slices/companySlice';
import {useRqVoiceArtists} from '../../react_query/users/CompanyContacts';
import {useQueryWithApiDataResponse} from '../../react_query/DefaultReactQueryOptions';
import {DialogClose} from '../dialogs/DialogFrame/DialogClose';
import SubmitButton from '../styles/buttons/SubmitButton';

const AiVoiceArtistAssign = ({message_type}: {message_type?: string}) => {
  const queryClient = useQueryClient();
  const companyId = useSelector(companyIdSelector);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [open, setOpen] = useState(false);

  const type = useCallback(() => {
    switch (message_type) {
      case 'base-message':
        return 'djvp';
      case 'stinger-message':
        return 'svp';
      default:
        return 'lmvp';
    }
  }, [message_type]);

  const {data, isFetched} = useRqVoiceArtists(companyId, {type: `${type()}`});

  const {data: selectedVoices, isFetching: voicesIsFetching} =
    useQueryWithApiDataResponse<number[]>(
      `v1/company/${companyId}/message/assign-ai-voice?type=${type()}`,
      {enabled: isFetched},
    );

  const handleToggle = (userId: number) => {
    setSelectedIds(prev => {
      if (prev.includes(userId)) {
        return prev.filter(id => id !== userId);
      }
      return [...prev, userId];
    });
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    queryClient.invalidateQueries({
      queryKey: [
        `v1/company/${companyId}/message/assign-ai-voice?type=${type()}`,
      ],
    });
  }, [companyId, queryClient, type]);

  useEffect(() => {
    if (isFetched && !voicesIsFetching && selectedVoices && message_type) {
      setSelectedIds(selectedVoices);
    }
  }, [isFetched, message_type, selectedVoices, voicesIsFetching]);

  if (open) {
    return (
      <Dialog onClose={handleClose} fullWidth maxWidth='sm' open>
        <DialogTitle>
          <Typography>Assign AI Voice</Typography>
          <DialogClose onClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <List>
            {data?.map(value => (
              <ListItemButton
                key={value.id}
                onClick={() => handleToggle(value.id)}
                dense>
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    checked={selectedIds.includes(value.id)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={value.name} />
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            payload={{
              type: type(),
              voices: selectedIds,
            }}
            url={`v1/company/${companyId}/message/assign-ai-voice`}
            title='Submit'
            successAction={handleClose}
            buttonProps={{disabled: !selectedIds.length, variant: 'contained'}}
          />
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <IconButton
      color='primary'
      size='small'
      onClick={() => setOpen(true)}
      title='AI Voice'>
      <InterpreterMode />
    </IconButton>
  );
};

export default AiVoiceArtistAssign;
