import {AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import {apiGet} from '../../axiosConfig';
import {IApiDataResponse} from '../../dto/API.dto';
import {IWalkupRequest} from '../../dto/IWalkupRequest.dto';
import {IWalkupPlayer} from '../../dto/IWalkupPlayer.dto';
import {IMediaTrack} from '../../dto/MediaTrack.dto';
import {ISongRequest} from '../../dto/SongRequest.dto';

export const getWalkupRequestShowApiPath = (
  company_id: number,
  walkup_request_id: number,
) => `/v1/company/${company_id}/walkup/${walkup_request_id}`;

export type IWalkupPlayerType = IWalkupPlayer & {
  file?: File | null;
  track_artist?: string;
  track_name?: string;
  type?: string;
  track?: IMediaTrack | null;
  song_request?: ISongRequest;
  isPlayerDisabled?: boolean;
  order?: number;
};

export type IWalkupRequestResp = IWalkupRequest & {
  players: IWalkupPlayerType[];
  form_student_ids: number[];
  station_id: number;
};

const getWalkupRequestShow = (
  company_id: number,
  walkup_request_id: number,
) => {
  return apiGet(
    getWalkupRequestShowApiPath(company_id, walkup_request_id),
  ).then((response: AxiosResponse<IApiDataResponse<IWalkupRequestResp>>) => {
    return response.data.data;
  });
};

export const useRqWalkupRequestShow = (
  company_id: number,
  walkup_request_id: number,
) => {
  return useQuery({
    queryKey: [getWalkupRequestShowApiPath(company_id, walkup_request_id)],
    queryFn: () => getWalkupRequestShow(company_id, walkup_request_id),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    enabled: Boolean(walkup_request_id),
  });
};

// Students forms list based on library id

export type IWalkupStudentForm = {
  id: number;
  company_id: number;
  walkup_library_id: number;
  name: string;
  link_status: boolean;
  link_token: string;
  expired_at: string;
  created_at: string;
};

export const walkupStudentFormsApiPath = (
  companyId: number,
  walkupId: number,
) => `v1/company/${companyId}/walkup/${walkupId}/student-form`;

const walkupStudentForms = (companyId: number, walkupId: number) => {
  return apiGet(walkupStudentFormsApiPath(companyId, walkupId)).then(
    (response: AxiosResponse<IApiDataResponse<IWalkupStudentForm[]>>) => {
      return response.data.data;
    },
  );
};

export const useRqWalkupStudentForms = (
  companyId: number,
  walkupId: number,
) => {
  return useQuery({
    queryKey: [walkupStudentFormsApiPath(companyId, walkupId)],
    queryFn: () => walkupStudentForms(companyId, walkupId),
    refetchOnWindowFocus: false,
    retry: false,
  });
};

// Students forms details based on student form id.

export type IWalkupStudentFormPlayers = IWalkupStudentForm & {
  players: IWalkupStudentFormResponse[];
};

export type IWalkupStudentFormResponse = {
  walkup_student_form_id: number;
  player: IWalkupPlayer | null;
  track: IMediaTrack | null;
};

export const walkupStudentFormResponseApiPath = (
  companyId: number,
  walkupId: number,
  formId: number,
) => `v1/company/${companyId}/walkup/${walkupId}/student-form/${formId}`;

const walkupStudentFormResponse = (
  companyId: number,
  walkupId: number,
  formId: number,
) => {
  return apiGet(
    walkupStudentFormResponseApiPath(companyId, walkupId, formId),
  ).then(
    (response: AxiosResponse<IApiDataResponse<IWalkupStudentFormPlayers>>) => {
      return response.data.data;
    },
  );
};

export const useRqWalkupStudentFormResponse = (
  companyId: number,
  walkupId: number,
  formId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [walkupStudentFormResponseApiPath(companyId, walkupId, formId)],
    queryFn: () => walkupStudentFormResponse(companyId, walkupId, formId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};

// Players list by student form token
export const getWalkupRequestShowByTokenApiPath = (token: string) =>
  `/v1/walkup-student-form/${token}`;

const getWalkupRequestShowByToken = (token: string) => {
  return apiGet(getWalkupRequestShowByTokenApiPath(token)).then(
    (response: AxiosResponse<IApiDataResponse<IWalkupRequestResp>>) => {
      return response.data.data;
    },
  );
};

export const useRqWalkupRequestShowByToken = (
  token: string,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getWalkupRequestShowByTokenApiPath(token)],
    queryFn: () => getWalkupRequestShowByToken(token),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    enabled: enabled,
  });
};
