import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Typography,
  List,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {Upload} from '@mui/icons-material';
import {DialogClose} from '../../../../../components/dialogs/DialogFrame/DialogClose';
import MusicPreviewBtn from '../../../../../components/music/MusicPreviewBtn';
import {VisuallyHiddenInput} from '../../../../admin/global-content/generic-stingers-list/GenericStingersAddTracksModal';
import {IMediaTrack} from '../../../../../dto/MediaTrack.dto';
import {apiPost} from '../../../../../axiosConfig';
import SkeletonListItem from '../../../../../components/skeleton/SkeletonListItem';
import AudioDurationValidation, {
  getAudioDurationFromFile,
} from '../../../../../components/utlis/Audio/GetAudioDuration';

type Props = {
  type: string;
  label?: string;
  companyId?: number;
  onClose: (track?: IMediaTrack) => void;
  isDisabled?: boolean;
};

const ProcessedFileUploadButton = ({
  label = 'Upload Processed File',
  type,
  companyId,
  onClose,
  isDisabled = false,
}: Props) => {
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(
    (track?: IMediaTrack) => {
      onClose(track);
      setOpen(false);
    },
    [onClose],
  );
  return (
    <>
      <Button
        disabled={isDisabled}
        variant='contained'
        color='secondary'
        className='ml-2'
        startIcon={<Upload />}
        onClick={() => setOpen(true)}>
        {label}
      </Button>
      {open && (
        <UploadProcessedFileDialog
          type={type}
          companyId={companyId}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default ProcessedFileUploadButton;

export const UploadProcessedFileDialog = ({
  type,
  companyId,
  onClose,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<null | File>(null);
  const [url, setUrl] = useState('');
  const [fileDuration, setFileDuration] = useState(0);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleFileChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length) {
        const newFile = event.target.files[0] as File;
        setFile(newFile);
        setFileDuration(await getAudioDurationFromFile(newFile));
      }
    },
    [],
  );

  const handleFileUpload = useCallback(() => {
    setIsSubmitting(true);
    const fd = new FormData();
    if (file) fd.append('file', file);
    fd.append('type', type);
    if (companyId) fd.append('company_id', companyId.toString());

    apiPost(`/v1/media-track/upload`, fd)
      .then(res => {
        onClose(res.data.media_track);
        setIsSubmitting(false);
      })
      .catch(_error => {
        setIsSubmitting(false);
      });
  }, [companyId, file, onClose, type]);

  const handleDelete = () => {
    setFile(null);
  };
  useEffect(() => {
    setUrl(file ? URL.createObjectURL(file) : '');
  }, [file]);

  return (
    <Dialog open maxWidth='sm' fullWidth onClose={handleClose}>
      <DialogTitle>
        <Typography>Please choose the processed file</Typography>
        <DialogClose onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <AudioDurationValidation
          durationInSec={fileDuration}
          maxDurationInMin={5}
        />
        <Stack direction={'row'} justifyContent={'center'}>
          <Button
            component='label'
            variant='outlined'
            className='h-30'
            size='medium'
            disabled={Boolean(file) || isSubmitting}
            startIcon={<CloudUploadIcon />}>
            Choose File
            <VisuallyHiddenInput
              type='file'
              accept='audio/*'
              onChange={handleFileChange}
            />
          </Button>
        </Stack>
        {isSubmitting ? <SkeletonListItem /> : null}
        {file && url && !isSubmitting && (
          <List sx={{mb: 2}}>
            <ListItem
              sx={{p: 2, background: '#0d1525'}}
              className='cursor-pointer'
              secondaryAction={
                <IconButton onClick={handleDelete} className='text-error'>
                  <DeleteOutlineIcon />
                </IconButton>
              }>
              <ListItemIcon>
                <MusicPreviewBtn
                  id={file.name}
                  url={url}
                  track_name={file.name}
                  track_artist={file.name}
                />
              </ListItemIcon>
              <ListItemText primary={file.name} />
            </ListItem>
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={handleFileUpload}
          disabled={isSubmitting || fileDuration / 60 >= 5}>
          Upload & Proceed
        </Button>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
