import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridRowHeightParams,
} from '@mui/x-data-grid-pro';
import {Box, Stack} from '@mui/material';
import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import Header from '../../../components/header/Header';
import TnnLogo from '../../../asset/images/tnn_logo.png';
import DetailViewContainerCard, {
  HeaderWithCenterTitle,
} from './TnnCmpt/DetailViewContainerCard';
import {GridCellExpand} from '../../../components/utlis/DataGrid/ExpandCellRender';
import {commonDateFormat} from '../../../Service/CommonService';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {
  ITnnCampaignsForCompanyParamProp,
  useRqListTnnCampaignsForCompany,
} from '../../../react_query/tnn_sponsors/TnnCampaignTypes';
import {DataGridCustPagination} from '../../../components/utlis/DataGrid/Pagination';
import InputSearch from '../../../components/styles/InputSearch';
import MusicPreviewBtn from '../../../components/music/MusicPreviewBtn';
import {IDataProps} from '../../../dto/Message.dto';

const TNNSponsorSelectedCampaign = () => {
  return (
    <Box>
      <Stack direction='column' className='px-11'>
        <Header IconMessage={TnnLogo} imgClassName='w-130 h-90 mr-4' Title='' />
      </Stack>
      <DetailViewContainerCard
        className='h-730 mx-10'
        header={<HeaderWithCenterTitle title='My Campaigns' />}
        body={<CampaignTypeDetailsRightCmpt />}
      />
    </Box>
  );
};

export default TNNSponsorSelectedCampaign;

const columns: GridColDef[] = [
  {
    field: 'campaign_name',
    headerName: 'Campaign Name',
    hideable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return <GridCellExpand value={params.row.name ?? ''} />;
    },
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    hideable: false,
    width: 170,
    renderCell: (params: GridRenderCellParams) => {
      return commonDateFormat(params.value);
    },
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    hideable: false,
    width: 170,
    renderCell: (params: GridRenderCellParams) => {
      return commonDateFormat(params.value);
    },
  },
  {
    field: 'number_of_runs',
    headerName: 'Number of Runs',
    hideable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return <GridCellExpand value={params.row.number_of_runs ?? ''} />;
    },
  },
  {
    field: 'url',
    headerName: 'Play Audio',
    hideable: false,
    sortable: false,
    type: 'actions',
    renderCell: (params: GridRenderCellParams) => {
      if (!params.row.messages || !Array.isArray(params.row.messages))
        return null;

      const playComponents = params.row.messages.map((message: IDataProps) => {
        const mediaUrl =
          message?.media_track?.url ?? message?.initial_url ?? '';

        return mediaUrl ? (
          <MusicPreviewBtn
            key={message?.id}
            id={mediaUrl}
            url={mediaUrl}
            track_name={
              message?.media_track?.track_name || params.row?.tnn_campaign?.name
            }
            track_artist={message?.media_track?.track_artist ?? 'TNN'}
          />
        ) : null;
      });
      return (
        <Stack direction='column' spacing={1} className='p-2'>
          {playComponents}
        </Stack>
      );
    },
  },
];

const getRowHeight = (params: GridRowHeightParams) => {
  const messageCount = params.model.messages?.length ?? 1; // Default to 1 if no messages
  return 50 + (messageCount - 1) * 40; // Base height 50px + 40px per additional message
};

const CampaignTypeDetailsRightCmpt = () => {
  const [query, setQuery] = useState<ITnnCampaignsForCompanyParamProp>(
    {} as ITnnCampaignsForCompanyParamProp,
  );
  const companyId = useSelector(companyIdSelector);
  const {data, isLoading} = useRqListTnnCampaignsForCompany(companyId, query);
  const handleSearch = useCallback(
    (value: string) => {
      if (value.length > 0) {
        setQuery(prev => {
          return {...prev, search: value};
        });
      } else {
        setQuery({} as ITnnCampaignsForCompanyParamProp);
      }
    },
    [setQuery],
  );

  return (
    <Box height={650} p={3}>
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <InputSearch
          className='w-300'
          placeholder='Search'
          value={query && query.search ? query.search : ''}
          onChange={handleSearch}
        />
      </Stack>
      <DataGridPro
        rows={data ?? []}
        columns={columns}
        loading={isLoading}
        pagination
        pageSizeOptions={[10, 25, 50]}
        slots={{
          pagination: DataGridCustPagination,
        }}
        slotProps={{
          columnsPanel: {
            disableHideAllButton: true,
            disableShowAllButton: true,
          },
        }}
        disableColumnMenu
        disableRowSelectionOnClick
        density='compact'
        initialState={{
          pagination: {paginationModel: {pageSize: 10}},
        }}
        getRowHeight={getRowHeight}
      />
    </Box>
  );
};
