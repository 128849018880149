import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IUser, IVoiceArtist} from '../../dto/User.dto';
import {IApiDataResponse} from '../../dto/API.dto';
import axiosInstance, {apiGet} from '../../axiosConfig';

type ICustUser = Pick<IUser, 'id' | 'email' | 'name'>;

export interface ICmpyContacts extends ICustUser {
  is_primary_contact: boolean;
  is_admin: boolean;
  is_user: boolean;
}

const getMessageOptions = (company_id: number) => {
  return apiGet(`/v1/company/${company_id}/contacts`).then(
    (response: AxiosResponse<IApiDataResponse<ICmpyContacts[]>>) => {
      return response.data.data;
    },
  );
};

export const useRqCompanyContacts = (company_id: number) => {
  return useQuery({
    queryKey: ['api/v1/company', company_id, 'contacts'],
    queryFn: () => getMessageOptions(company_id),
    retry: false,
    enabled: Boolean(company_id),
  });
};

const getVoiceArtists = (companyId: number, params: {type: string}) => {
  return axiosInstance
    .get(`/v1/company/${companyId}/voice-artists`, {params: params})
    .then((response: AxiosResponse<IApiDataResponse<IVoiceArtist[]>>) => {
      return response.data?.data;
    });
};

const getVoiceArtistLastUsed = (companyId: number, params: {type: string}) => {
  return axiosInstance
    .get(`/v1/company/${companyId}/voice-artists/base-last-used`, {
      params: params,
    })
    .then((response: AxiosResponse<IApiDataResponse<number>>) => {
      return response.data?.data;
    });
};

export const useRqVoiceArtists = (
  companyId: number,
  params: {type: string; is_assigned_voices?: 0 | 1; voices?: number[]},
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [`api/v1/company/${companyId}/voice-artists`, params],
    queryFn: () => getVoiceArtists(companyId, params),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

export const useRqQueryLastUsedVoiceArtist = (
  companyId: number,
  params: {type: string},
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [
      `api/v1/company/${companyId}/voice-artists/base-last-used`,
      params,
    ],
    queryFn: () => getVoiceArtistLastUsed(companyId, params),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};
