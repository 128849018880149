import {v4 as uuidv4} from 'uuid';
import {IScripts} from './BaseMessagesReducer';
import {IMediaTrack} from '../../../../dto/MediaTrack.dto';
import {
  MessageValidationProps,
  checkMessagesValid,
} from '../../../../Service/CommonService';

export const invalidBaseMessageName = (name: string) =>
  name.length === 0 || name.length > 50;

export const invalidBaseMessage = (message: IScripts) => {
  if (!message?.media_track && message?.message === '') {
    return true;
  }
  return false;
};

export const invalidBaseSeason = (message: IScripts) =>
  !message?.isAllSeason && !message?.seasons.length;

export const inValidBaseMessageExists = (params: {
  isNewBaseMessage: boolean;
  messages: IScripts[];
  isGT: boolean;
  isNR?: boolean;
}) => {
  const {isNewBaseMessage, messages, isGT, isNR} = params;
  const maxLength = isGT ? 5 : 6;
  const filterMessage = messages.filter(x => x.message !== '' && x.name !== '');
  if (!filterMessage.length) {
    return true;
  }
  if (isNewBaseMessage && filterMessage.length < maxLength) {
    return true;
  }

  const validationMessages: MessageValidationProps[] = messages
    .filter(({name, message}) => {
      const trimmedName = name?.trim() || '';
      const trimmedMessage = message?.trim() || '';
      return (
        (trimmedName !== '' && trimmedMessage !== '') ||
        trimmedName !== '' ||
        trimmedMessage !== ''
      );
    })
    .map(({name = '', message = '', isAllSeason = true, seasons = []}) => ({
      name,
      message,
      isAllSeason,
      seasons,
    }));

  if (!checkMessagesValid(validationMessages)) {
    return true;
  }

  if (isNR && filterMessage.some(x => !x.voice_artist)) {
    return true;
  }
  return false;
};

export const updateBaseMessages = (options: {
  messages: IScripts[];
  uuid: string;
  value: IScripts;
}) => {
  const {messages, uuid, value} = options;
  return messages.map(message => {
    if (message.uuid === uuid) {
      return {
        ...message,
        ...value,
      };
    }
    return message;
  });
};

export const addNewTrackToBaseMessages = (options: {
  messages: IScripts[];
  tracks: IMediaTrack[];
}) => {
  const {messages, tracks} = options;

  const prevMessages = messages.filter(val => {
    return val.message.length !== 0 || val.name.length !== 0 || val.media_track;
  });
  const newTrackMessages: IScripts[] = [];
  for (const track of tracks) {
    if (!messages.some(val => tracks.some(t => t.id === val.media_track?.id))) {
      newTrackMessages.push({
        id: Date.now(),
        uuid: uuidv4(),
        message: track.track_name,
        name: '',
        requestable_type: 'base-message',
        media_track: track,
        events: [],
        seasons: [],
        isAllSeason: true,
        other_event_name: '',
      } as IScripts);
    }
  }
  return [...newTrackMessages, ...prevMessages];
};

export const addNewMessageToBaseMessages = (options: {
  messages: IScripts[];
}) => {
  const {messages} = options;
  return [
    {
      id: Date.now(),
      uuid: uuidv4(),
      name: '',
      message: '',
      events: [],
      seasons: [],
      isAllSeason: true,
      requestable_type: 'base-message',
    },
    ...messages,
  ] as IScripts[];
};

export const addTrackToBaseMessage = (options: {
  messages: IScripts[];
  track: IMediaTrack;
}) => {
  const {messages, track} = options;
  return messages.map((o, i) => {
    if (i === 0) {
      return {...o, media_track: track};
    }
    return o;
  });
};
