import {AxiosResponse} from 'axios';
import {useQuery, UseQueryOptions, UseQueryResult} from '@tanstack/react-query';
import {apiGet} from '../axiosConfig';
import {IApiDataResponse} from '../dto/API.dto';

export const useQueryWithApiResponse = <T>(
  url: string,
  options?: UseQueryOptions<T, unknown>,
): UseQueryResult<T, unknown> => {
  return useQuery<T>({
    queryKey: [url],
    queryFn: () =>
      apiGet(url).then((response: AxiosResponse<T>) => response.data),
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });
};

export const useQueryWithApiDataResponse = <T>(
  url: string,
  options?: UseQueryOptions<T, unknown>,
): UseQueryResult<T, unknown> => {
  return useQuery<T>({
    queryKey: [url],
    queryFn: () =>
      apiGet(url).then(
        (response: AxiosResponse<IApiDataResponse<T>>) => response.data.data,
      ),
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });
};
