import {useState} from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {useLocation, useNavigate} from 'react-router-dom';

import IconDashboard from '../../asset/icons/icon_menu_dashboard.svg';
import IconUserManagement from '../../asset/icons/icon_menu_usermanagement.svg';
import IconTutorialManagement from '../../asset/icons/icon_menu_tutorial.svg';
import IconOnboarding from '../../asset/icons/icon_menu_onboarding.svg';
import IconProcessing from '../../asset/icons/icon_menu_processing.svg';
import IconGlobalContent from '../../asset/icons/icon_menu_globalcontent.svg';
import IconGlobalControl from '../../asset/icons/icon_menu_gloablcontrol.svg';
import IconMusicSelection from '../../asset/icons/icon_menu_musicselection.svg';
import IconReports from '../../asset/icons/icon_menu_reports.svg';
import IconInternalStaff from '../../asset/icons/icon_menu_internalstaff.svg';
import IconNoPlay from '../../asset/icons/icon_menu_noplay.svg';
import IconOutstandingDownloads from '../../asset/icons/icon_menu_downloads.svg';
import IconReferral from '../../asset/icons/icons_referral.svg';
import IconTnnSponsor from '../../asset/icons/icon_menu_tnn.svg';
import IconAdd from '../../asset/icons/icon_addsongs.svg';
import IconKnowledgeBase from '../../asset/icons/icon_book_search.svg';
import IconAlert from '../../asset/icons/alert.svg';
import IconPartnerDashboard from '../../asset/icons/partner_dashboard.svg';

const pages = [
  {
    path: '/',
    name: 'Dashboard',
    startIcon: IconDashboard,
    scopes:
      'is-admin,internal-users:list,internal-users:create,internal-users:edit',
  },
  {
    path: '/admin/partners',
    name: 'Partner Dashboard',
    startIcon: IconPartnerDashboard,
    scopes: 'is-admin,partner-dashboard',
  },
  {
    path: '/admin/alerts',
    name: 'Alerts',
    startIcon: IconAlert,
    scopes: 'is-admin,partner-alerts',
  },
  {
    path: '/admin/status-management',
    name: 'Status Management',
    startIcon: IconReports,
    scopes:
      'is-admin,sponsor-commercial-task-status:list,music-review-task-status:list,messaging-task-status:list',
  },
  {
    path: '/admin/internal-users',
    name: 'Internal Staff Management',
    startIcon: IconInternalStaff,
    scopes:
      'is-admin,internal-users:list,internal-users:create,internal-users:edit',
  },
  {
    path: '/admin/partner-users',
    name: 'User Management',
    startIcon: IconUserManagement,
    scopes: 'is-admin,partner-accounts:list',
  },
  {
    path: '/tutorial-management',
    name: 'Tutorial Management',
    startIcon: IconTutorialManagement,
    scopes: 'is-admin,tutorial:list,tutorial:create,tutorial:update-status',
  },
  {
    path: '/admin/knowledge-base-management',
    name: 'Knowledge Base Management',
    startIcon: IconKnowledgeBase,
    scopes:
      'is-admin,knowledge:list,knowledge:create,knowledge:update,knowledge:delete',
  },
  {
    path: '/partner',
    name: 'Onboarding',
    startIcon: IconOnboarding,
    scopes: 'is-admin,company:create',
  },
  {
    path: '/processing-center',
    name: 'Processing Center',
    startIcon: IconProcessing,
    scopes: 'is-admin,processing-center:list',
  },
  {
    name: 'Global Content',
    startIcon: IconGlobalContent,
    scopes: 'is-admin,global-content:list',
    subMenu: [
      {
        path: '/admin/global-content/generic-stingers',
        name: 'Generic Stingers',
        scopes: 'is-admin,generic-stinger:list',
      },
      {
        path: '/admin/global-content/network-esm',
        name: 'Network ESMs',
        scopes:
          'is-admin,network-esm:list,network-esm:create,network-esm:update,network-esm:delete',
      },
      {
        path: '/admin/global-content/network-odm',
        name: 'Network ODMs',
        scopes:
          'is-admin,network-odm:list,network-odm:create,network-odm:update,network-odm:delete',
      },
      {
        path: '/admin/global-content/generic-base',
        name: 'Generic Base Messages',
        scopes: 'is-admin,generic-base-message:list',
      },
      {
        path: '/admin/global-content/commercial',
        name: "PSA's / Commercial",
        scopes: 'is-admin,commercial:list',
      },
      {
        path: '/admin/global-content/wristband-messages',
        name: 'Wristband Messages',
        scopes: 'is-admin,wristband-color:list,wristband-color:store',
      },
    ],
  },
  {
    name: 'Global Controls',
    startIcon: IconGlobalControl,
    scopes: 'is-admin,global-controls:list',
    subMenu: [
      {
        path: '/admin/global-controls/manage-product',
        name: 'Manage Product Genres',
        scopes: 'is-admin,manage-product:list',
      },
      {
        path: '/admin/global-controls/music-genre',
        name: 'Music Genre',
        scopes: 'is-admin,music-genre:list',
      },
      {
        path: '/admin/global-controls/sports-management',
        name: 'Sports Management',
        scopes: 'is-admin,events:list,',
      },
      {
        path: '/admin/global-controls/music-voice-personnel',
        name: 'Manage Voice Personnel',
        scopes: 'is-admin,music-voice-personnel:list',
      },
      {
        path: '/admin/commercial-type',
        name: 'Sponsor Commercial Categories',
        scopes: 'is-admin,commercial-type:list',
      },
      {
        path: '/admin/global-controls/template-pattern',
        name: 'Template Pattern',
        scopes:
          'is-admin,template-pattern:list,template-pattern:create,template-pattern:update,template-pattern:delete',
      },
      {
        path: '/admin/global-controls/message-creator',
        name: 'Message Creators',
        scopes: 'is-admin,music-creator:list',
      },
    ],
  },
  {
    path: '/admin/music-section',
    name: 'Music Section',
    startIcon: IconMusicSelection,
    scopes: 'is-admin,music-section:list',
  },
  {
    path: '/admin/no-play',
    name: 'NO Play',
    startIcon: IconNoPlay,
    scopes: 'is-admin,no-play:list',
  },
  {
    path: '/admin/tnn/control',
    name: 'TNN',
    startIcon: IconTnnSponsor,
    scopes:
      'is-admin,tnn-sponsor:list,tnn-sponsor:create,tnn-sponsor:show,tnn-sponsor-campaign:create,tnn-sponsor-campaign:list,tnn-dynamic-rate-card:campaign-list',
  },
  {
    name: 'Reports',
    startIcon: IconReports,
    scopes: 'is-admin,music-section:list',
    subMenu: [
      {
        path: '/admin/reports/music-review',
        name: 'Music Review',
        scopes: 'is-admin',
      },
      {
        path: '/admin/reports/account-branding-report',
        name: 'Missing Account branding',
        scopes: 'is-admin',
      },
      {
        path: '/admin/reports/base-message-report',
        name: 'Base Message Report',
        scopes: 'is-admin,base-message-voice-report:list',
      },
      {
        path: '/admin/reports/esm-odm-report',
        name: 'ESM/ODM Report',
        scopes: 'is-admin,esm-odm-report:list',
      },
      {
        path: '/admin/reports/gone-silent-report',
        name: 'Gone Silent Report',
        scopes: 'is-admin,gone-silent-report:list',
      },
      {
        path: '/admin/reports/copyright-report',
        name: 'Copyright Report',
        scopes: 'is-admin,copyright-report:list', //Without copyright-report:list update permission can't be add
      },
    ],
  },
  {
    path: '/admin/referral-management',
    name: 'Referral Management',
    startIcon: IconReferral,
    scopes: 'is-admin,referral:list',
  },
  {
    path: '/admin/outstanding-downloads',
    name: 'Outstanding Downloads',
    startIcon: IconOutstandingDownloads,
    scopes: 'is-admin,music-section:list',
  },
  {
    path: '/admin/demo-stinger',
    name: 'Demo Stinger Fulfillment',
    startIcon: IconAdd,
    scopes: 'is-admin,demo-stinger-fulfillment:list',
  },
];

const HeaderAdminMenu = ({handleClick}: {handleClick: () => void}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menu, setMenu] = useState('');
  const [, setSubMenu] = useState('');

  const handleMenu = (name: string, path?: string) => {
    setMenu(prev => {
      if (prev === name) {
        return '';
      }
      return name;
    });
    if (path) {
      navigate(path);
      handleClick();
    }
  };

  const handleSubMenu = (menuName: string, path: string) => {
    setMenu(menuName);
    setSubMenu(prev => {
      if (prev === path) {
        return '';
      }
      return path;
    });
    navigate(path);
    handleClick();
  };

  return (
    <List className='admin-menu scrollbar overflow-auto py-5' component='nav'>
      {pages.map(page => (
        <>
          <ListItemButton
            key={page.path}
            onClick={() => handleMenu(page.name, page.path)}
            className={`py-3 pl-11 pr-6`}
            sx={{
              background:
                location.pathname === page.path ||
                (page.subMenu &&
                  page.subMenu.some(
                    subPage => location.pathname === subPage.path,
                  ))
                  ? 'var(--blue-variant7)!important'
                  : '',
            }}>
            <ListItemIcon>
              <img
                src={page.startIcon}
                alt='menu icon'
                className='w-20 h-20 mr-4'
              />
            </ListItemIcon>
            <ListItemText primary={page.name} />
            {page.subMenu ? (
              menu === page.name ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : null}
          </ListItemButton>
          {page.subMenu && (
            <Collapse in={menu === page.name} timeout='auto' unmountOnExit>
              <List
                disablePadding
                sx={{background: 'var(--blue-variant10) !important'}}>
                {page.subMenu.map((subPage, subIndex) => (
                  <ListItemButton
                    key={subIndex}
                    className={`py-3 pl-25 pr-6`}
                    sx={{
                      background:
                        location.pathname === subPage.path
                          ? 'var(--blue-variant7)!important'
                          : '',
                    }}
                    onClick={() => handleSubMenu(page.name, subPage.path)}>
                    <ListItemText
                      primary={
                        <span>
                          <span className='mr-4'>-</span> {subPage.name}
                        </span>
                      }
                      className='text-white-50'
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </>
      ))}
    </List>
  );
};

export default HeaderAdminMenu;
