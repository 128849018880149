import {useState} from 'react';
import {useSelector} from 'react-redux';
import {NotInterested} from '@mui/icons-material';
import {Box, IconButton, Stack} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {
  IMsgRequestCust,
  queryKeyMessageRequestList,
} from '../../../../react_query/messages/MessagesByCmpId';
import {
  companyIdSelector,
  isNRorSZSelector,
} from '../../../../store/Slices/companySlice';
import {useStoreSelector} from '../../../../store/configstore';
import {apiPatch} from '../../../../axiosConfig';
import {UserCanAny} from '../../../../components/UserCan';
import IconViewButton from '../../../../components/Button/IconViewButton';
import IconEditButton from '../../../../components/Button/IconEditButton';
import BaseMsgConvertToEventMsgButton from './BaseMsgConvertToEventMsgButton';
import DialogApiRequestStatus from '../../../../components/dialogs/DialogApiRequestStatus';
import CommonDialog from '../../../../components/CommonDialog';
import CustomModal from '../../../../components/modal/CustomModal';
import ViewBaseMsgList from './ViewBaseMsgList';
import DeleteRow from '../../../../components/utlis/DataGrid/DeleteRow';
import BaseMessageCreate from './BaseMessageCreate';

type ActionProps = {
  req: IMsgRequestCust;
  status: string;
};
const BaseMsgDataGridItemAction = ({req, status}: ActionProps) => {
  const companyId = useStoreSelector(companyIdSelector);
  const isNRSZ = useSelector(isNRorSZSelector);
  const [apiState, setApiState] = useState({status: '', error: null});
  const [open, setOpen] = useState(false);
  const [viewId, setViewId] = useState<number>(0);
  const [editId, setEditId] = useState<number>(0);
  const queryClient = useQueryClient();
  const handleStatus = () => {
    setOpen(true);
  };
  const handleSubmit = () => {
    const id = req.id;
    setApiState({
      status: 'pending',
      error: null,
    });
    const payload = {
      status: status === 'Active' ? '0' : '1', //If status already in active we have to switch it's state
    };
    apiPatch(`/v1/company/${companyId}/message/base-message/${id}`, payload)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [queryKeyMessageRequestList(companyId, 'base-message')],
          refetchType: 'active',
        });
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleEdit = () => {
    setApiState({
      status: '',
      error: null,
    });
  };
  const handleClose = () => {
    setApiState({
      status: '',
      error: null,
    });
    setOpen(false);
  };
  const openViewBaseModal = (id: number) => {
    setViewId(id);
  };
  const closeViewBaseModal = () => {
    setViewId(0);
  };

  const openEditBaseModal = (id: number) => {
    setEditId(id);
  };
  const closeEditBaseModal = () => {
    setEditId(0);
  };
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
      className='w-full'
      spacing={2}>
      <Box
        className='flex items-center justify-center w-full'
        sx={{gap: '16px'}}>
        <UserCanAny scope='is-admin,partner,base-message:view'>
          <IconViewButton
            onClick={() => openViewBaseModal(req.id)}
            aria-label='view'
          />
        </UserCanAny>
        <UserCanAny scope='is-admin,base-message:delete'>
          <DeleteRow
            dialog_content={<>Are you sure to delete this message</>}
            refreshQueryKey={queryKeyMessageRequestList(
              companyId,
              'base-message',
            )}
            url={`v1/company/${companyId}/message/base-message/${req.id}`}
          />
        </UserCanAny>
        {req.status !== 'In Progress' && (
          <UserCanAny scope='is-admin,base-message:update'>
            <IconEditButton
              onClick={() => openEditBaseModal(req.id)}
              aria-label='edit'
            />
          </UserCanAny>
        )}
        {req.status !== 'In Progress' ? (
          <UserCanAny scope='is-admin,base-message:update'>
            {req.status === 'Active' ? (
              <IconButton
                color='error'
                title='Deactivate'
                onClick={handleStatus}>
                <NotInterested />
              </IconButton>
            ) : (
              <IconButton
                color='primary'
                title='Restore'
                onClick={handleStatus}>
                <NotInterested />
              </IconButton>
            )}
          </UserCanAny>
        ) : null}
        {isNRSZ &&
        !req.completed_base_script_tasks_count &&
        !['Active', 'Inactive'].includes(req.status) ? (
          <UserCanAny scope='is-admin,partner,convert-base-message'>
            <BaseMsgConvertToEventMsgButton id={req.id} />
          </UserCanAny>
        ) : null}
      </Box>

      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleSubmit}
          onEdit={handleEdit}
          onClose={handleClose}
        />
      )}
      {open && (
        <CommonDialog
          open={open}
          status={status === '1' ? true : false}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}></CommonDialog>
      )}
      <CustomModal
        open={viewId !== 0}
        onClose={closeViewBaseModal}
        title='View Base Message'
        body={<ViewBaseMsgList id={viewId} />}
        actionButtonText='Close Modal'
        onActionButtonClick={closeViewBaseModal}
        maxwidth='740'
        customHeight='482'
      />
      {editId !== 0 && (
        <BaseMessageCreate
          update_id={editId}
          handleCancel={closeEditBaseModal}
        />
      )}
    </Stack>
  );
};

export default BaseMsgDataGridItemAction;
