import {useCallback} from 'react';
import {v4 as uuidv4} from 'uuid';
import {useSelector} from 'react-redux';
import {useRqViewBaseListMessagesByRequestID} from '../../../../react_query/messages/MessagesByCmpId';
import {companyIdSelector} from '../../../../store/Slices/companySlice';
import BaseMessageRequest from './BaseMessageRequest';
import {IScripts} from './BaseMessagesReducer';

const BaseMessageCreate = ({
  update_id,
  handleCancel,
}: {
  update_id?: number;
  handleCancel: () => void;
}) => {
  const companyId = useSelector(companyIdSelector);
  const {data, isFetching} = useRqViewBaseListMessagesByRequestID(
    companyId,
    Number(update_id),
    Boolean(update_id),
  );
  const messages = useCallback(() => {
    if (!isFetching && data) {
      return [
        {
          id: data.id,
          uuid: uuidv4(),
          message: data.message?.message ?? '',
          name: data.name ?? '',
          events: data.events ?? [],
          other_event_name: '',
          requestable_type: 'base-message',
          seasons: data.seasons ?? [],
          isAllSeason: Boolean(data.is_all_seasons),
          media_track: data.message?.media_track ?? undefined,
          voice_artist_id: data.message?.voice_artist?.id,
        },
      ];
    }
    return [
      {
        id: Date.now(),
        uuid: uuidv4(),
        name: '',
        message: '',
        events: [],
        seasons: [],
        isAllSeason: true,
        requestable_type: 'base-message',
        other_event_name: '',
      },
    ];
  }, [data, isFetching]);
  return !isFetching ? (
    <BaseMessageRequest
      handleCancel={handleCancel}
      update_id={update_id}
      defaultMessages={messages() as IScripts[]}
      defaultTracks={[]}
      defaultVoiceNames={data?.voice_artists ?? []}
      defaultVoices={[]}
      defaultEvents={data?.events ?? []}
      defaultIsAllSeason={Boolean(data?.is_all_seasons)}
      defaultSchools={data?.schools ?? []}
      defaultSeasons={data?.seasons ?? []}
      defaultStations={data?.stations ?? []}
      defaultStatus={data?.status ?? null}
    />
  ) : null;
};
export default BaseMessageCreate;
