import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Autocomplete, TextField, Typography} from '@mui/material';
import {IStation} from '../../../dto/Station.dto';
import {useRqListCompanyStations} from '../../../react_query/stations/CompanyStationList';
import {companyIdSelector} from '../../../store/Slices/companySlice';

const ExcludedStationsDropdownGetStation = ({
  stationIds,
  selectedStation,
  setSelectedStation,
  onChangeAction,
}: {
  stationIds: number[];
  selectedStation: IStation | null;
  setSelectedStation: (station: IStation) => void;
  onChangeAction: () => void;
}) => {
  const companyId = useSelector(companyIdSelector);

  const {data, isFetching, isError} = useRqListCompanyStations(
    Number(companyId),
  );

  useEffect(() => {
    if (data && !isFetching && !isError && data.length === 1) {
      setSelectedStation((data[0] as IStation) ?? null);
    }
  }, [data, isError, isFetching, setSelectedStation]);

  if (isError) {
    return (
      <Typography color='error'>Error in loading stations list</Typography>
    );
  }

  return (
    <Autocomplete
      size='small'
      id='stations-list'
      onChange={(
        _: React.SyntheticEvent<Element, Event>,
        station: IStation | null,
      ) => {
        if (station) setSelectedStation(station);
        onChangeAction?.();
      }}
      value={selectedStation}
      options={data.filter(o => !stationIds.includes(o.id)) ?? []}
      loading={isFetching}
      getOptionLabel={(option: IStation) => option.label || option.name}
      renderInput={params => <TextField {...params} label='Stations' />}
    />
  );
};

export default ExcludedStationsDropdownGetStation;
