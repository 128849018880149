import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useSelector} from 'react-redux';
import {
  Autocomplete,
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {IScripts} from './BaseMessagesReducer';
import {
  companyIdSelector,
  isGTSelector,
  isNRSelector,
} from '../../../../store/Slices/companySlice';
import {
  addNewMessageToBaseMessages,
  invalidBaseMessage,
  invalidBaseMessageName,
  updateBaseMessages,
} from './BaseMessageFunctionalities';
import MessageAddButton from '../../../../components/styles/buttons/MessageAddButton';
import {MessageAlert} from '../../../messages/event-specific/EsmMessages';
import MusicPreviewBtn from '../../../../components/music/MusicPreviewBtn';
import {CustomTextarea} from '../../../../components/styles/CustomTextarea';
import {ConditionalRender} from '../../../../components/UserCan';
import {useRqVoiceArtists} from '../../../../react_query/users/CompanyContacts';
import {IVoiceArtist} from '../../../../dto/User.dto';

const BaseMessageForm = ({
  message,
  setMessages,
  isNewBaseMessage,
  lastUsedVoiceArtist,
  update_id,
  formStatus = null,
}: {
  message: IScripts;
  setMessages: Dispatch<SetStateAction<IScripts[]>>;
  isNewBaseMessage: boolean;
  lastUsedVoiceArtist?: number;
  update_id?: number;
  formStatus: string | null;
}) => {
  const isGT = useSelector(isGTSelector);
  const isNR = useSelector(isNRSelector);

  const handleName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMessages(prev =>
        updateBaseMessages({
          messages: prev,
          uuid: message.uuid,
          value: {...message, name: event.target.value},
        }),
      );
    },
    [message, setMessages],
  );

  const handleMessage = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMessages(prev =>
        updateBaseMessages({
          messages: prev,
          uuid: message.uuid,
          value: {...message, message: event.target.value},
        }),
      );
    },
    [message, setMessages],
  );

  const handleAddMessageDetails = useCallback(() => {
    setMessages(prev =>
      addNewMessageToBaseMessages({
        messages: prev,
      }),
    );
  }, [setMessages]);

  const inValidForm = useMemo(() => {
    return (
      invalidBaseMessageName(`${message?.name}`) ||
      invalidBaseMessage(message) ||
      (isNR && !message.voice_artist?.id)
    );
  }, [message, isNR]);

  return (
    <>
      <MessageAlert name={`${message?.name}`} message={message?.message} />
      <Box>
        <Grid
          container
          alignItems='center'
          justifyContent={isNR ? 'space-between' : 'flex-start'}>
          <Grid item md={6}>
            <TextField
              id='message-name-text'
              placeholder='Message Name*'
              variant='outlined'
              value={message?.name}
              onChange={handleName}
              className='m-h-40 h-40 w-full max-w-400 p-0 my-3'
              InputProps={{
                style: {
                  minHeight: '40px',
                },
              }}
            />
          </Grid>
          <ConditionalRender condition={isNR}>
            <Grid item md={3}>
              <DJVoiceAristDropDown
                message={message}
                setMessages={setMessages}
                preSelect={
                  message.voice_artist_id ?? lastUsedVoiceArtist ?? undefined
                }
                isUpdate={Boolean(update_id)}
              />
            </Grid>
          </ConditionalRender>
        </Grid>

        <Stack
          spacing={1}
          direction='row'
          alignItems='end'
          className='textarea_newmsg mb-5'>
          <CustomTextarea
            placeholder='Enter Message here'
            onChange={handleMessage}
            value={message?.message}
            disabled={formStatus === 'Active' || formStatus === 'Inactive'}
          />
          <Stack spacing={2}>
            {message?.media_track ? (
              <MusicPreviewBtn
                id={message.uuid}
                url={message.media_track.url}
                track_name={message.media_track.track_name || message.name}
                track_artist={
                  message.media_track.track_artist || message.message
                }
              />
            ) : null}
            {!formStatus ? (
              <MessageAddButton
                isValid={!inValidForm}
                onClick={handleAddMessageDetails}
              />
            ) : null}
          </Stack>
        </Stack>
      </Box>
      {isNewBaseMessage ? (
        <Typography className='text-13 text-white opacity-70'>
          Note: You will need a minimum of {isGT ? 'five' : 'six'} base messages
          before you will be able to submit your request.
        </Typography>
      ) : null}
    </>
  );
};

export const DJVoiceAristDropDown = ({
  message,
  setMessages,
  preSelect,
  isUpdate = false,
}: {
  message: IScripts;
  setMessages: Dispatch<SetStateAction<IScripts[]>>;
  preSelect?: number;
  isUpdate?: boolean;
}) => {
  const companyId = useSelector(companyIdSelector);
  const {data = [], isLoading} = useRqVoiceArtists(
    companyId,
    {type: 'djvp', is_assigned_voices: 1},
    Boolean(companyId),
  );

  const legacyVoice: IVoiceArtist = useMemo(
    () => ({
      id: -1,
      profile: 'Legacy Voice',
      name: 'Legacy Voice',
      sample_url: '',
    }),
    [],
  );

  const updatedOptions = useMemo(() => {
    return isUpdate && !data.some(artist => artist.id === -1)
      ? [legacyVoice, ...data]
      : data;
  }, [data, isUpdate, legacyVoice]);

  const initialValue = useMemo(() => {
    if (preSelect !== undefined && data.length) {
      return data.find(artist => artist.id === preSelect) || null;
    }
    return isUpdate ? legacyVoice : null;
  }, [data, preSelect, isUpdate, legacyVoice]);

  const [selectedValue, setSelectedValue] = useState<IVoiceArtist | null>(
    initialValue,
  );

  useEffect(() => {
    setMessages(prev =>
      updateBaseMessages({
        messages: prev,
        uuid: message.uuid,
        value: {...message, voice_artist: selectedValue!},
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const handleChange = useCallback(
    (value: IVoiceArtist | null) => {
      if (selectedValue?.id !== value?.id) {
        setSelectedValue(value);
        setMessages(prev =>
          updateBaseMessages({
            messages: prev,
            uuid: message.uuid,
            value: {...message, voice_artist: value ?? undefined},
          }),
        );
      }
    },
    [message, selectedValue, setMessages],
  );

  return (
    <Autocomplete
      id='dj-voice-artist-drop-down'
      readOnly={isUpdate}
      options={updatedOptions.length ? updatedOptions : [legacyVoice]}
      value={selectedValue || undefined}
      autoHighlight
      loading={isLoading}
      disableClearable
      getOptionLabel={option => option.name}
      onChange={(_, value: IVoiceArtist | null) => {
        handleChange(value);
      }}
      renderOption={(props, option) => (
        <Box
          key={option.id}
          component='li'
          gap={2}
          className='music-preview-btn mx-2 d-flex justify-content-between'
          {...props}>
          {option.id !== -1 ? (
            <MusicPreviewBtn
              id={option.id}
              url={option.sample_url}
              track_name={option.name}
              track_artist={option.name}
            />
          ) : null}
          <span>{option.name}</span>
        </Box>
      )}
      renderInput={params => <TextField {...params} label='Voice Artists' />}
    />
  );
};

export default BaseMessageForm;
