import dayjs, {Dayjs} from 'dayjs';
import {useSelector} from 'react-redux';
import {useCallback, useEffect, useState} from 'react';
import IconCalendar from '../../../asset/icons/icon_calendar.svg';
import IconListMenu from '../../../asset/icons/icon_lismenu.svg';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';
import {IListQueryParms} from '../../../react_query/messages/MessagesByCmpId';
import TimedMsgDataGrid from './TimedMsgDataGrid';
import TimedMsgCalendar from './TimedMsgCalendar';
import {UserCanAny} from '../../../components/UserCan';
import IconAdd from '../../../asset/icons/icon_messaging.svg';
import InputSearch from '../../../components/styles/InputSearch';
import TimedMessageCreate from './TimedMessageCreate';
import CustomModal from '../../../components/modal/CustomModal';
import TimedMsgDetailed from './TimedMsgDetails/TimedMsgDetailed';
import {tabsStyleRadioMode} from '../../../components/styles/TabStyles';
import TimedMessageListOfDate from './TimedMessageListOfDate';
import {useRqTimedMsgExistence} from '../../../react_query/messages/timed-messages/TimedMsgExistance';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {BackDropCmpt} from '../../../components/layouts/AuthUsers';
import {IEsmStatus} from '../../../dto/EventSpecificMessageRequest.dto';
import AiVoiceArtistAssign from '../../../components/header/AiVoiceArtistAssign';

const daysOfWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
const currentDate = new Date();
const dayOfWeek = currentDate.getDay();
const currentDay = daysOfWeek[dayOfWeek] ?? 'sunday';

const TimedMessageHome = () => {
  const companyId = useSelector(companyIdSelector);
  const [status, setStatus] = useState<string>('Active');
  const [view, setView] = useState('calendar');
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [viewId, setViewId] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [query, setQuery] = useState<IListQueryParms>({
    per_page: 10,
    page: 1,
    selected_day: currentDay,
  });

  const {data: timedExists, isFetching} = useRqTimedMsgExistence(companyId);

  const toggleCreateDialog = useCallback(() => {
    setShowCreateDialog(val => !val);
  }, []);

  const handleDays = useCallback(
    (_event: React.SyntheticEvent, newValue: string) => {
      setQuery(prev => {
        return {
          ...prev,
          selected_day: newValue,
        };
      });
    },
    [setQuery],
  );

  const handleCloseViewModal = () => {
    setViewId(0);
  };

  const handleSearch = useCallback((val: string) => {
    setSearch(val);
  }, []);

  useEffect(() => {
    if (!isFetching && !timedExists) {
      setView('table');
    }
  }, [isFetching, timedExists]);

  if (isFetching) {
    return <BackDropCmpt />;
  }

  return (
    <Box className='GridTableList pt-12'>
      {view === 'table' ? (
        <Grid container spacing={2}>
          <Grid item md={8} className='px-10 pb-5 pt-0'>
            <Tabs
              value={query.selected_day}
              onChange={handleDays}
              variant='scrollable'
              aria-label='tabs'
              sx={tabsStyleRadioMode}>
              <Tab value='monday' label='Monday' />
              <Tab value='tuesday' label='Tuesday' />
              <Tab value='wednesday' label='Wednesday' />
              <Tab value='thursday' label='Thursday' />
              <Tab value='friday' label='Friday' />
              <Tab value='saturday' label='Saturday' />
              <Tab value='sunday' label='Sunday' />
            </Tabs>
          </Grid>
          <Grid item md={4} className='pl-10 pb-5 pt-0'>
            <TimedActionButtons
              view={view}
              handleSearch={handleSearch}
              search={search}
              setView={setView}
              toggleCreateDialog={toggleCreateDialog}
              setStatus={setStatus}
              status={status}
              setQuery={setQuery}
            />
          </Grid>
          <Grid item xs>
            <TimedMsgDataGrid
              setViewId={setViewId}
              setQuery={setQuery}
              query={query}
              search={search}
              toggleCreateDialog={toggleCreateDialog}
              status={status}
              setStatus={setStatus}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item md={8} className='bg-dark px-10 pb-5 pt-0'>
            <TimedMsgCalendar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              setSelectedIds={setSelectedIds}
            />
          </Grid>
          <Grid item md={4} className='pl-10 pb-5 pt-0'>
            <TimedActionButtons
              view={view}
              handleSearch={handleSearch}
              search={search}
              setView={setView}
              toggleCreateDialog={toggleCreateDialog}
              setStatus={setStatus}
              status={status}
              setQuery={setQuery}
            />
            <TimedMessageListOfDate
              search={search}
              setViewId={setViewId}
              selectedIds={selectedIds}
              selectedDate={selectedDate}
            />
          </Grid>
        </Grid>
      )}

      {showCreateDialog && (
        <TimedMessageCreate
          onClose={toggleCreateDialog}
          setStatus={setStatus}
        />
      )}
      <UserCanAny scope='is-admin,partner,timed-message:view'>
        <CustomModal
          open={viewId ? true : false}
          onClose={handleCloseViewModal}
          title='Message Preview'
          body={<TimedMsgDetailed id={viewId} />}
          actionButtonText='Close Modal'
          onActionButtonClick={handleCloseViewModal}
          maxwidth='740'
          customHeight='605'
        />
      </UserCanAny>
    </Box>
  );
};

export default TimedMessageHome;

function TimedActionButtons({
  view,
  search,
  handleSearch,
  setView,
  toggleCreateDialog,
  setStatus,
  status,
  setQuery,
}: {
  view: string;
  search: string;
  handleSearch: (val: string) => void;
  setView: (val: string) => void;
  toggleCreateDialog: () => void;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  status: string;
  setQuery: React.Dispatch<React.SetStateAction<IListQueryParms>>;
}) {
  const handleStatus = (newStatus: string) => {
    if (newStatus === 'Inactive') {
      setView('table');
    }
    setStatus(newStatus);
    setQuery(prev => {
      return {
        ...prev,
        status: newStatus,
      };
    });
  };
  return (
    <Stack
      className='flex items-center justify-between mb-4'
      direction='row'
      spacing={2}>
      <InputSearch
        className='max-w-200 w-full'
        value={search || ''}
        placeholder='Search'
        onChange={handleSearch}
      />
      <Stack direction='row' spacing={1} alignItems='center'>
        {status === 'Active' && (
          <>
            <IconButton
              className={`${view === 'calendar' ? 'btnactive' : null}`}
              color='primary'
              size='small'
              onClick={() => setView('calendar')}>
              <img src={IconCalendar} width='20' alt='' />
            </IconButton>
            <IconButton
              color='primary'
              onClick={() => setView('table')}
              className={`${view === 'table' ? 'btnactive' : null}`}>
              <img src={IconListMenu} alt='' width='20' />
            </IconButton>
          </>
        )}
        <UserCanAny scope={`is-admin,message-ai-voice:assign`}>
          <AiVoiceArtistAssign message_type={'timed-message'} />
        </UserCanAny>
        <UserCanAny scope='is-admin,partner,timed-message:create'>
          <Button
            onClick={toggleCreateDialog}
            variant='outlined'
            className='w-150'
            size='small'
            startIcon={<img src={IconAdd} alt='icon' />}>
            Create New
          </Button>
        </UserCanAny>
        <UserCanAny scope='is-admin,partner,staff'>
          <FormControl className='mr-2 h-30 w-110'>
            <Select
              value={status}
              onChange={event => handleStatus(event.target.value as IEsmStatus)}
              className='min-h-30'
              inputProps={{'aria-label': 'Without label'}}>
              <MenuItem value='Active'>Active</MenuItem>
              <MenuItem value='Inactive'>Inactive</MenuItem>
            </Select>
          </FormControl>
        </UserCanAny>
      </Stack>
    </Stack>
  );
}
