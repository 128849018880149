import {Dispatch, SetStateAction, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Edit} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {isInternalSelector} from '../../../../store/Slices/userSlice';
import {IScripts} from './BaseMessagesReducer';
import MusicPreviewBtn from '../../../../components/music/MusicPreviewBtn';
import IconDeleteButton from '../../../../components/Button/IconDeletebutton';
import BaseMessageForm from './BaseMessageForm';
import {manipulateText} from '../../../../components/utlis/date-time/SecondsNumberToTimeFormat';
import {IMediaTrack} from '../../../../dto/MediaTrack.dto';
import {ConditionalRender} from '../../../../components/UserCan';
import {isNRSelector} from '../../../../store/Slices/companySlice';

type Props = {
  formStatus: string | null;
  messages: IScripts[];
  setMessages: Dispatch<SetStateAction<IScripts[]>>;
  isNewBaseMessage: boolean;
  lastUsedVoiceArtist?: number;
  update_id?: number;
  setSelectedTracks: React.Dispatch<React.SetStateAction<IMediaTrack[]>>;
};
const BaseMessageInput = ({
  formStatus,
  messages,
  setMessages,
  isNewBaseMessage,
  lastUsedVoiceArtist,
  update_id,
  setSelectedTracks,
}: Props) => {
  const isStaff = useSelector(isInternalSelector);
  const isNR = useSelector(isNRSelector);
  const deleteMultiSelectData = (msg: IScripts) => {
    setMessages(prev => prev.filter(v => v.uuid !== msg.uuid));
    if (msg.media_track) {
      setSelectedTracks(prev => {
        const removedSongs = prev.filter(val => val.id !== msg.media_track?.id);
        return removedSongs;
      });
    }
  };

  const handleEdit = useCallback(
    (uuid: string) => {
      setMessages(prev => {
        const messages = [...prev.filter(o => o.name || o.message)];
        const index = messages.findIndex(message => message.uuid === uuid);
        if (index !== -1) {
          const splicedMessage = messages.splice(index, 1)[0] as IScripts; // Remove the message from its current position
          messages.unshift(splicedMessage); // Move the message to the first position
        }
        return [...messages];
      });
    },
    [setMessages],
  );

  return (
    <>
      <BaseMessageForm
        key={messages[0]?.uuid}
        message={messages[0] as IScripts}
        setMessages={setMessages}
        isNewBaseMessage={isNewBaseMessage}
        lastUsedVoiceArtist={
          messages.length === 1 ? lastUsedVoiceArtist : undefined
        }
        update_id={update_id}
        formStatus={formStatus}
      />
      {messages && messages.length > 0 && (
        <Box className='max-h-250 scrollbar overflow-auto'>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {isStaff ? <TableCell>Play</TableCell> : null}
                  <TableCell align='left' width={297}>
                    <Typography className='text-16 opacity-50 text-white'>
                      Message Name
                    </Typography>
                  </TableCell>
                  <TableCell align='left'>
                    <Typography className='text-16 opacity-50 text-white'>
                      Message
                    </Typography>
                  </TableCell>
                  <ConditionalRender condition={isNR}>
                    <TableCell align='left'>
                      <Typography className='text-16 opacity-50 text-white'>
                        DJ Voice
                      </Typography>
                    </TableCell>
                  </ConditionalRender>
                  <TableCell width={72}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.map((item, index) => {
                  if (index) {
                    return (
                      <TableRow key={item.uuid}>
                        {isStaff ? (
                          <TableCell width={80} align='left'>
                            {item.media_track ? (
                              <MusicPreviewBtn
                                id={item.media_track.id}
                                url={item.media_track.url}
                                track_name={
                                  item.media_track.track_name || item?.name
                                }
                                track_artist={item.media_track.track_artist}
                              />
                            ) : null}
                          </TableCell>
                        ) : null}
                        <TableCell align='left'>
                          <Typography
                            variant='caption'
                            className='text-16 text-white'
                            title={item.name}>
                            {manipulateText(item.name ?? '', 15)}
                          </Typography>
                        </TableCell>
                        <TableCell align='left'>
                          <Typography
                            variant='caption'
                            className='text-16 text-white'
                            title={item.message}>
                            {manipulateText(item.message ?? '', 50)}
                          </Typography>
                        </TableCell>
                        <ConditionalRender condition={isNR}>
                          <TableCell align='left'>
                            <Typography
                              variant='caption'
                              className='text-16 text-white gap-2 flex items-center'
                              title={item.voice_artist?.name}>
                              <MusicPreviewBtn
                                id={item.voice_artist?.id!}
                                url={item.voice_artist?.sample_url!}
                                track_name={item.voice_artist?.name!}
                                track_artist={item.voice_artist?.name!}
                              />
                              <span>{item.voice_artist?.name!}</span>
                            </Typography>
                          </TableCell>
                        </ConditionalRender>
                        <TableCell align='center'>
                          <Stack direction='row' spacing={1}>
                            <IconButton
                              aria-label='edit'
                              onClick={() => handleEdit(item.uuid)}>
                              <Edit />
                            </IconButton>
                            <IconDeleteButton
                              onClick={() => {
                                deleteMultiSelectData(item);
                              }}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};
export default BaseMessageInput;
