import {useSelector} from 'react-redux';
import {useQueryClient} from '@tanstack/react-query';
import {useEffect, useMemo, useState} from 'react';
import {apiPost} from '../../../../axiosConfig';
import DialogApiRequestStatus from '../../../../components/dialogs/DialogApiRequestStatus';
import {
  StepperLayout,
  useStepperState,
} from '../../../../components/layouts/stepper/StepperLayout';
import {
  companyIdSelector,
  isGTSelector,
  isNRorSZSelector,
  isNRSelector,
  productLevelSelector,
} from '../../../../store/Slices/companySlice';
import BaseMessageInput from './BaseMessageInput';
import BaseMessageReview from './BaseMessageReview';
import {IScripts} from './BaseMessagesReducer';
import BaseVoiceArtistSelection from './BaseVoiceArtistSelection';
import {useRqBaseMessagesCount} from '../../../../react_query/base-messages/BaseMessageRequestQueries';
import {queryKeyMessageRequestList} from '../../../../react_query/messages/MessagesByCmpId';
import {
  addNewTrackToBaseMessages,
  addTrackToBaseMessage,
  inValidBaseMessageExists,
} from './BaseMessageFunctionalities';
import {IMediaTrack} from '../../../../dto/MediaTrack.dto';
import BaseSeasonAndTracksAdd from './BaseSeasonAndTracksAdd';
import {IEventType} from '../../../../dto/EventType.dto';
import BaseMessageInfo from './BaseMessageInfo';
import {ICompanySchool} from '../../../../dto/CompanySchool.dto';
import {IStation} from '../../../../dto/Station.dto';
import {ISeasonMessage, seasonsDetails} from '../../../../dto/EditMessage.dto';
import {useRqQueryLastUsedVoiceArtist} from '../../../../react_query/users/CompanyContacts';

const BaseMessageRequest = ({
  defaultVoices,
  defaultVoiceNames,
  defaultMessages,
  defaultTracks,
  defaultEvents,
  defaultSchools,
  defaultStations,
  defaultIsAllSeason,
  defaultSeasons,
  handleCancel,
  defaultStatus,
  update_id,
}: {
  defaultVoices: string[];
  defaultVoiceNames: string[];
  defaultMessages: IScripts[];
  defaultTracks: IMediaTrack[];
  defaultEvents: IEventType[];
  defaultSchools: ICompanySchool[];
  defaultStations: IStation[];
  defaultIsAllSeason: boolean;
  defaultSeasons: seasonsDetails[];
  handleCancel: () => void;
  defaultStatus: string | null;
  update_id?: number;
}) => {
  const isNRSZ = useSelector(isNRorSZSelector);
  const isGT = useSelector(isGTSelector);
  const isNR = useSelector(isNRSelector);
  const productLevel = useSelector(productLevelSelector);
  const isLevel1 = productLevel.includes('Level 1');
  const companyId = useSelector(companyIdSelector);
  const [messages, setMessages] = useState(defaultMessages);
  const [voice, setVoice] = useState(defaultVoices);
  const [voiceName, setVoiceName] = useState(defaultVoiceNames);
  const [apiState, setApiState] = useState({status: '', error: null});
  const [selectedTracks, setSelectedTracks] = useState(defaultTracks);
  const {activeStepIndex, onNextStep, onPreviousStep, onStepClick} =
    useStepperState(0);
  const [othersEventName, setOthersEventName] = useState('');
  const [events, setEvents] = useState(defaultEvents);
  const [schools, setSchools] = useState(defaultSchools);
  const [stations, setStations] = useState(defaultStations);
  const [seasons, setSeasons] = useState<ISeasonMessage>({
    seasons: defaultSeasons,
    isAllSeason: defaultIsAllSeason,
  });
  const {data: baseCount, isFetching: baseCountFetching} =
    useRqBaseMessagesCount(companyId);
  const queryClient = useQueryClient();

  const isOptionsSelected = useMemo(() => {
    if (isGT) {
      const areEventsValid =
        events.length !== 0 &&
        !events.some(event => event.id === 0 && !othersEventName);
      const areStationsValid = stations.length !== 0;
      const areSchoolsValid = schools.length !== 0;
      return !(areEventsValid && areStationsValid && areSchoolsValid);
    } else if (isNRSZ) {
      let seasonsStatus = false;
      if (seasons.isAllSeason) {
        seasonsStatus = true;
      }
      if (!seasons.isAllSeason) {
        if (seasons.seasons.length > 0) {
          seasonsStatus = true;
        }
      }
      const areStationsValid = stations.length !== 0;
      return !(seasonsStatus && areStationsValid);
    }
    return true;
  }, [events, stations, schools, isGT, othersEventName, isNRSZ, seasons]);

  const isNewBaseMessage = useMemo(() => {
    if (baseCount && !baseCountFetching && baseCount.count === 0) {
      return true;
    }
    return false;
  }, [baseCount, baseCountFetching]);

  const {data: lastUsedVoiceArtist} = useRqQueryLastUsedVoiceArtist(
    companyId,
    {type: 'djvp'},
    isNR && !isNewBaseMessage && !Boolean(update_id),
  );

  const isInvalidMessages = useMemo(() => {
    if (
      update_id &&
      (defaultStatus === 'Active' || defaultStatus === 'Inactive')
    ) {
      return messages.some(o => !o.name || !o.media_track);
    }
    return inValidBaseMessageExists({isGT, isNewBaseMessage, messages, isNR});
  }, [defaultStatus, isGT, isNR, isNewBaseMessage, messages, update_id]);
  const steps = useMemo(() => {
    if (baseCountFetching) {
      return [];
    }
    const voiceVerify =
      isNRSZ && isNewBaseMessage
        ? isLevel1
          ? !(voice.length === 1)
          : !(voice.length === 3)
        : false;
    const defaultSteps = [
      {
        label: 'Select Options',
        disabled: voiceVerify,
      },
      {
        label: update_id ? 'Edit Message' : 'Create Message',
        disabled: isOptionsSelected,
      },
      {
        label: 'Review',
        disabled: isInvalidMessages,
      },
    ];
    return defaultSteps;
  }, [
    baseCountFetching,
    isNRSZ,
    isNewBaseMessage,
    isLevel1,
    voice.length,
    update_id,
    isInvalidMessages,
    isOptionsSelected,
  ]);
  const handleSubmit = () => {
    setApiState({
      status: 'pending',
      error: null,
    });
    const payload = {
      voices: voice,
      isAllSeason: seasons.isAllSeason,
      seasonId: seasons.seasons.map(o => o.id),
      stations: stations.map(o => o.id),
      messages: messages
        .filter(({message, name}) => {
          if (update_id) {
            return true;
          }
          return message !== '' && name !== '';
        })
        .map(val => {
          const value = {
            name: val.name,
            message: val.message,
            media_track_id: val.media_track?.id ?? null,
          };

          if (isNR) {
            return {
              ...value,
              voice_artist: val.voice_artist,
            };
          }
          return value;
        }),
    };
    if (isGT) {
      Object.assign(payload, {
        schools: schools.map(o => o.id),
      });
      if (othersEventName) {
        Object.assign(payload, {
          other_event_name: othersEventName,
        });
      }
      const eventIds = events.map(event => event.id).filter(id => id > 0);
      if (eventIds.length) {
        Object.assign(payload, {
          eventIds: eventIds,
        });
      }
    }
    const apiPath = update_id
      ? `/v1/company/${companyId}/message/base-message/${update_id}?_method=PUT`
      : `/v1/company/${companyId}/message/base-message`;
    apiPost(apiPath, payload)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [queryKeyMessageRequestList(companyId, 'base-message')],
          refetchType: 'active',
        });
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  };
  const handleEdit = () => {
    setApiState({
      status: '',
      error: null,
    });
  };
  useEffect(() => {
    if (selectedTracks.length) {
      if (update_id) {
        setMessages(messages =>
          addTrackToBaseMessage({
            messages,
            track: selectedTracks[0] as IMediaTrack,
          }),
        );
      } else {
        setMessages(messages =>
          addNewTrackToBaseMessages({
            messages,
            tracks: selectedTracks,
          }),
        );
      }
    }
  }, [selectedTracks, setMessages, update_id]);

  return (
    <StepperLayout
      steps={steps}
      activeStepIndex={activeStepIndex}
      onBack={onPreviousStep}
      onNext={onNextStep}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      onStepClick={onStepClick}
      isSubmitted={apiState.status !== ''}
      isLastHide={false}
      loading={apiState.status === 'pending'}
      title={`${update_id ? 'Edit' : 'Create New '} Message `}>
      {(() => {
        const label = steps?.[activeStepIndex]?.label ?? '';
        switch (label) {
          case 'Select Voice Artist':
            return (
              <BaseVoiceArtistSelection
                voice={voice}
                setVoice={setVoice}
                setVoiceName={setVoiceName}
              />
            );
          case 'Select Options':
            return (
              <BaseMessageInfo
                setOthersEventName={setOthersEventName}
                othersEventName={othersEventName}
                setEvents={setEvents}
                events={events}
                schools={schools}
                setSchools={setSchools}
                stations={stations}
                setStations={setStations}
                seasons={seasons}
                setSeasons={setSeasons}
              />
            );
          case 'Edit Message':
          case 'Create Message':
            return (
              <>
                {!defaultStatus || defaultStatus === 'In Progress' ? (
                  <BaseSeasonAndTracksAdd
                    trackLimit={update_id ? 1 : 20}
                    selectedTracks={selectedTracks}
                    setSelectedTracks={setSelectedTracks}
                    title={`${update_id ? 'Edit' : 'Create'} Message`}
                  />
                ) : null}
                <BaseMessageInput
                  formStatus={defaultStatus}
                  messages={messages}
                  setMessages={setMessages}
                  isNewBaseMessage={isNewBaseMessage}
                  lastUsedVoiceArtist={lastUsedVoiceArtist}
                  update_id={update_id}
                  setSelectedTracks={setSelectedTracks}
                />
              </>
            );

          case 'Review':
            return (
              <BaseMessageReview
                messages={messages}
                voiceName={voiceName}
                setMessages={setMessages}
                events={events}
                schools={schools}
                stations={stations}
                seasons={seasons}
                formStatus={defaultStatus}
              />
            );

          default:
            return null;
        }
      })()}
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleSubmit}
          onEdit={handleEdit}
          onClose={handleCancel}
        />
      )}
    </StepperLayout>
  );
};

export default BaseMessageRequest;
