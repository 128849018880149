import {useSelector} from 'react-redux';
import {
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {Add, Clear, Done} from '@mui/icons-material';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {IOnDemandStation, IOnDemCustLib} from './OndemandStepper';
import {IStation} from '../../../dto/Station.dto';
import OndemandCustomLibrary from './OndemandCustomLibrary';
import {useRqCustomLibrary} from '../../../react_query/custom_libarary/ListCustomLibraries';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import IconDeleteButton from '../../../components/Button/IconDeletebutton';
import IconEditButton from '../../../components/Button/IconEditButton';
import ExcludedStationsDropdownGetStation from '../../../components/models/stations/ExcludedStationsDropdown';

const OnDemandStationLibrarySelection = ({
  stationsList,
  setStationsList,
}: {
  stationsList: IOnDemandStation[];
  setStationsList: React.Dispatch<React.SetStateAction<IOnDemandStation[]>>;
}) => {
  const companyId = useSelector(companyIdSelector);
  const [station, setStation] = useState<IStation | null>(null);
  const [libraries, setLibraries] = useState<IOnDemCustLib[]>([]);
  const [selectedLibraries, setSelectedLibraries] = useState<IOnDemCustLib[]>(
    [],
  );

  const {
    data: customLib,
    isFetching: customLibIsFetching,
    isError: customLibIsError,
  } = useRqCustomLibrary(
    Number(companyId),
    {
      station_ids: [Number(station?.id)],
    },
    !!station,
  );

  const clearStationList = useCallback(() => {
    setStation(null);
    setSelectedLibraries([]);
  }, []);

  const addStationList = useCallback(() => {
    const newStation = {
      ...station,
      custom_libraries: selectedLibraries,
    } as IOnDemandStation;
    setStationsList(prev => {
      if (prev.some(o => o.id === newStation.id)) {
        return prev.map(station => {
          if (station.id === newStation.id) {
            return newStation;
          }
          return station;
        });
      }
      return [...prev, newStation];
    });
    clearStationList();
  }, [clearStationList, selectedLibraries, setStationsList, station]);

  const editStation = (station: IOnDemandStation) => {
    setStation(station);
    setSelectedLibraries(station.custom_libraries);
  };

  const deleteStation = (id: number) => {
    clearStationList();
    setStationsList(prev => prev.filter(o => o.id !== id));
  };

  const stationIds = useMemo(() => stationsList.map(o => o.id), [stationsList]);

  const isUpdate = useMemo(() => {
    return stationsList.some(o => o.id === station?.id);
  }, [station?.id, stationsList]);

  useEffect(() => {
    if (customLib && !customLibIsFetching && !customLibIsError) {
      setLibraries([
        {id: 0, name: 'Add New', station_name: ''} as IOnDemCustLib,
        ...(customLib.data.map(v => {
          return {...{id: v.id, name: v.name, station_name: ''}, new: false};
        }) as IOnDemCustLib[]),
      ]);
    }
  }, [customLib, customLibIsError, customLibIsFetching]);

  return (
    <Grid container spacing={1}>
      <Grid item sm={4}>
        <ExcludedStationsDropdownGetStation
          selectedStation={station}
          setSelectedStation={setStation}
          stationIds={stationIds}
          onChangeAction={() => setSelectedLibraries([])}
        />
      </Grid>
      <Grid item sm={7}>
        <OndemandCustomLibrary
          libraries={libraries}
          selected={selectedLibraries}
          setSelected={setSelectedLibraries}
        />
      </Grid>
      <Grid item sm={1}>
        <Stack direction='row' spacing={1}>
          <IconButton
            title={isUpdate ? 'Update' : 'Add'}
            disabled={!station || !selectedLibraries.length}
            onClick={addStationList}>
            {isUpdate ? <Done /> : <Add />}
          </IconButton>
          <IconButton title='Clear' onClick={clearStationList}>
            <Clear />
          </IconButton>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        {stationsList.length ? (
          <OnDemandStationsList
            stationsList={stationsList}
            editStation={editStation}
            deleteStation={deleteStation}
          />
        ) : (
          <Typography>Please select the stations</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default OnDemandStationLibrarySelection;

export const OnDemandStationsList = ({
  stationsList,
  editStation,
  deleteStation,
}: {
  stationsList: IOnDemandStation[];
  editStation?: (station: IOnDemandStation) => void;
  deleteStation?: (id: number) => void;
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='table'>
        <TableHead>
          <TableRow>
            <TableCell width={100}>Station</TableCell>
            <TableCell>Custom Libraries</TableCell>
            {deleteStation && editStation && <TableCell>Manage</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {stationsList.map(o => (
            <TableRow key={o.id}>
              <TableCell width={100}>{o.label || o.name}</TableCell>
              <TableCell sx={{maxWidth: 600}}>
                {o.custom_libraries.map(o => (
                  <Chip label={o.name} key={o.id} sx={{m: 0.25}} />
                ))}
              </TableCell>
              {deleteStation && editStation && (
                <TableCell>
                  <Stack direction='row' spacing={1}>
                    <IconEditButton onClick={() => editStation(o)} />
                    <IconDeleteButton onClick={() => deleteStation(o.id)} />
                  </Stack>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
