import {useCallback} from 'react';
import {ButtonProps, IconButton, Tooltip} from '@mui/material';
import IconPlay from '../../asset/icons/icon_play.svg';
import IconPause from '../../asset/icons/icon_pause.svg';
import {useDispatch, useSelector} from 'react-redux';
import {playSong, stopPlayer} from '../../store/Slices/playerSlice';
import {RootState} from '../../store/configstore';

type Props = {
  id: string | number | null;
  url: string;
  size?: boolean;
  track_name: string;
  track_artist: string;
  buttonProps?: ButtonProps;
  toolTipLabel?: string;
};

const MusicPreviewBtn = ({
  id,
  url,
  size,
  track_name,
  track_artist,
  buttonProps,
  toolTipLabel = '',
}: Props) => {
  const dispatch = useDispatch();

  const {id: currentId, isPlaying} = useSelector(
    (state: RootState) => state.player,
  );

  const sizeSet = size ? {width: '29px', height: '29px'} : {};
  const togglePlay = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      const isCurrentTrack = currentId === Number(id);
      if ((isCurrentTrack && !isPlaying) || currentId !== Number(id)) {
        dispatch(
          playSong({
            songUrl: url,
            track_name: track_name,
            track_artist: track_artist,
            id: Number(id) || 0,
          }),
        );
      } else if (isCurrentTrack && isPlaying) {
        dispatch(stopPlayer());
      }
    },
    [dispatch, url, id, track_name, track_artist, isPlaying, currentId],
  );

  return (
    <Tooltip title={toolTipLabel}>
      <IconButton
        key={id}
        className='p-0'
        aria-label='play'
        onClick={togglePlay}
        {...buttonProps}
        disabled={!url && url?.trim() === ''}>
        <img
          src={isPlaying && currentId === Number(id) ? IconPause : IconPlay}
          alt=''
          style={sizeSet}
        />
      </IconButton>
    </Tooltip>
  );
};

export default MusicPreviewBtn;

export const MusicPreviewBtnDisable = () => (
  <IconButton aria-label='play' disabled={true}>
    <img src={IconPlay} alt='' />
  </IconButton>
);
